import React from 'react'

/** What to show while pages are loading */
export default class Loading extends React.Component {
  render () {
    return (
      <div>
                Loading...
      </div>
    )
  }
}
